import React from "react";
// import AnchorLink from "react-anchor-link-smooth-scroll";
import BitServicePic from "../../../assets/images/bit-service.svg";
import TrigonBg from "../../../assets/images/trigon_bg.svg";
import "./index.scss"

function About() {
    return (
        <section className="about" id="about">
            <div className="container">
                <h1>О компании</h1>
                <div className="about__wrapper">
                    <div className="about__item">
                        <p className="about__title">БИТСЕРВИС</p>
                        <img src={BitServicePic} alt=""/>
                    </div>
                    <div className="about__item">
                        <p className="about__desc">БИТСЕРВИС - логистический оператор, который предоставляет полный комплекс услуг по управлению цепочками поставок.</p>
                        <p className="about__text">Используя современные информационные технологии и мировой опыт, БИТСЕРВИС разрабатывает логистические решения в соответствии с индивидуальными потребностями клиентов и спецификой различных рынков.</p>
                        {/*<div className="about__down">*/}
                        {/*    <AnchorLink offset="50" href="#byshop" className="btn btn_primary">Складская логистика</AnchorLink>*/}
                        {/*    <div className="about__rectangle"></div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
            <img src={TrigonBg} alt=""/>
        </section>
    )
}

export default About;