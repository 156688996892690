import React from "react";
import { Route } from "wouter";
import MainPage from "../pages/MainPage"

function Router() {
    return (
        <>
            <Route path="/">
                <MainPage />
            </Route>
        </>
    )
}

export default Router