import React from "react";
import Router from "./jsx/core/router";
import TagManager from "react-gtm-module";

function App() {
  TagManager.initialize({
    gtmId: "GTM-KNJ9P5R",
  });
  return (
    <div className="page">
      <Router />
    </div>
  );
}

export default App;
