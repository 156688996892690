import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import AnchorLink from "react-anchor-link-smooth-scroll";
// import i18n from "../../core/i18n";
import BytServiceLogo from "../../../assets/icons/logo.jpg";
import "./index.scss"

function Header(props) {
    // const changeLanguage = (lng) => {
    //     i18n.changeLanguage(lng);
    // }
    const [open, setOpen] = useState(false);
    // const [collapse, setCollapse] = useState(false);

    const handleOpen = () => {
        setOpen(true);
        document.querySelector('.page').classList.add('menu-opened')
    };

    const handleClose = () => {
        setOpen(false);
        // setCollapse(false);
        document.querySelector('.page').classList.remove('menu-opened')
    };

    return (
        <>
            <header>
                <div className="container">
                    <a className="nav__link" href="/" >
                        <img className="nav__logo" src={BytServiceLogo} alt="" />
                    </a>
                    <div className="navbar">
                        <nav className="nav">
                            <div className="menu-toggler" onClick={handleOpen}>
                                <span />
                                <span />
                                <span />
                            </div>

                            <AnchorLink
                                className="nav__link hidden_xs"
                                offset="180"
                                href="#about"
                            >
                                О компании
                            </AnchorLink>
                            <AnchorLink
                                className="nav__link hidden_xs"
                                offset="180"
                                href="#advantage"
                            >
                                Складская логистика
                            </AnchorLink>
                            <AnchorLink
                                className="nav__link hidden_xs"
                                offset="180"
                                href="#geography"
                            >
                                География
                            </AnchorLink>
                            <AnchorLink
                                className="nav__link hidden_xs"
                                offset="100"
                                href="#vacancies"
                            >
                                Вакансии
                            </AnchorLink>
                            <AnchorLink
                                className="nav__link hidden_xs"
                                offset="180"
                                href="#contacts"
                            >
                                Контактная информация
                            </AnchorLink>
                        </nav>
                    </div>
                </div>
            </header>
            <div className={`menu menu_mobile ${open ? 'active' : ''}`}>
                <div className="menu__block">
                    <div className="menu-close" onClick={handleClose}>
                        <span className="menu-close__span menu-close__span--left" />
                        <span className="menu-close__span menu-close__span--right" />
                    </div>
                    <nav>
                        <AnchorLink
                            className="nav__link"
                            offset="180"
                            href="#about"
                            onClick={handleClose}
                        >
                            О компании
                        </AnchorLink>
                        <AnchorLink
                            className="nav__link"
                            offset="180"
                            href="#advantage"
                            onClick={handleClose}
                        >
                            Складская логистика
                        </AnchorLink>
                        <AnchorLink
                            className="nav__link"
                            offset="180"
                            href="#geography"
                            onClick={handleClose}
                        >
                            География
                        </AnchorLink>
                        <AnchorLink
                            className="nav__link hidden_xs"
                            offset="50"
                            href="#vacancies"
                            onClick={handleClose}
                        >
                            Вакансии
                        </AnchorLink>
                        <AnchorLink
                            className="nav__link"
                            offset="180"
                            href="#contacts"
                            onClick={handleClose}
                        >
                            Контактная информация
                        </AnchorLink>
                    </nav>
                </div>
            </div>
        </>
    )
}

export default withTranslation()(Header)
