import React from "react";
// import AnchorLink from "react-anchor-link-smooth-scroll";
// import BitServicePic from "../../../assets/images/bit-service.svg";
import Flex from "../../../assets/icons/1.svg";
import Individual from "../../../assets/icons/2.svg";
import HighTech from "../../../assets/icons/3.svg";
import Pl from "../../../assets/icons/4.svg";
import Rectangle from "../../../assets/icons/rectangle.svg";
import Line from "../../../assets/images/line.svg";
import "./index.scss";

function Advantage() {
  return (
    <section className="advantage" id="advantage">
      <div className="container">
        <div className="advantage__header">
          <img src={Rectangle} alt="" />
          <h1>Складская логистика</h1>
          <img src={Rectangle} alt="" />
        </div>
        <h2>Найдем решение под Ваши задачи</h2>
        <div className="advantage__wrapper">
          <div className="advantage__item">
            <img src={Flex} alt="" />
            <p className="advantage__desc">
              Гибкий подход в решении нестандартных задач с учетом специфики
              клиента
            </p>
          </div>
          <div className="advantage__item">
            <img src={Individual} alt="" />
            <p className="advantage__desc">
              Индивидуальный подход к формированию тарифов
            </p>
          </div>
          <div className="advantage__item">
            <img src={HighTech} alt="" />
            <p className="advantage__desc">
              Высокотехнологичные ИТ-решения складского учета, высокая
              эффективность управления грузопотоками
            </p>
          </div>
          <div className="advantage__item">
            <img src={Pl} alt="" />
            <p className="advantage__desc">3PL-услуги широкого спектра</p>
          </div>
        </div>
        {/*<div className="advantage__footer">*/}
        {/*    <img src={Rectangle} alt=""/>*/}
        {/*    <AnchorLink offset="50" href="#byshop" className="btn btn_primary">География</AnchorLink>*/}
        {/*    <img src={Rectangle} alt=""/>*/}
        {/*</div>*/}
        <img className="advantage__line" src={Line} alt="" />
      </div>
    </section>
  );
}

export default Advantage;
