import React, { useState } from "react";
import Icon_1 from "../../../assets/icons/icon.svg";
import Icon_2 from "../../../assets/icons/icon-1.svg";
import Icon_3 from "../../../assets/icons/icon-2.svg";
import Icon_4 from "../../../assets/icons/icon-3.svg";
import Icon_5 from "../../../assets/icons/icon-4.svg";
import Line from "../../../assets/images/line.svg";
import "./index.scss";

function Vacancies() {
    return (
        <section className="vacancies" id="vacancies">
            <img className="vacancies__line" src={Line} alt=""/>
            <div className="container">
                <h1>Вакансии</h1>
                <h2>Работа в прогрессивной компании</h2>

                <div className="vacancies__wrapper">

                    <div className="vacancies__contents">
                        <a href="https://hh.ru/employer/4993974" target="_blank" rel="noreferrer noopener" className="vacancies__block">
                            <img src={Icon_1} alt="битсервис" />
                            <div className="vacancies__title">Работник склада</div>
                        </a>
                        <a href="https://hh.ru/employer/4993974" target="_blank" rel="noreferrer noopener" className="vacancies__block">
                            <img src={Icon_2} alt="битсервис" />
                            <div className="vacancies__title">Водитель электроштабелера</div>
                        </a>
                    </div>

                    <div className="vacancies__contents">
                        <a href="https://hh.ru/employer/4993974" target="_blank" rel="noreferrer noopener" className="vacancies__block">
                            <img src={Icon_3} alt="битсервис" />
                            <div className="vacancies__title">Водитель категории B,C</div>
                        </a>
                        <a href="https://hh.ru/employer/4993974" target="_blank" rel="noreferrer noopener" className="vacancies__block vacancies__none">
                            <img src={Icon_4} alt="битсервис" />
                            <div className="vacancies__title">Комплектовщик</div>
                        </a>
                    </div>
                </div>

                <div className="vacancies__wrapper">
                    <a href="https://hh.ru/employer/4993974" target="_blank" rel="noreferrer noopener" className="vacancies__block vacancies__none--last">
                        <img src={Icon_4} alt="битсервис" />
                        <div className="vacancies__title">Комплектовщик</div>
                    </a>
                    <a href="https://hh.ru/employer/4993974" target="_blank" rel="noreferrer noopener"  className="vacancies__block vacancies__block--last">
                        <img src={Icon_5} alt="битсервис" />
                        <div className="vacancies__title">Логист</div>
                    </a>
                </div>

            </div>
        </section>
    )
}
export default Vacancies;