import React, { useState } from "react";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import classNames from "classnames";
import MapsPoint from "../../../assets/icons/maps-point.svg";
import "./index.scss";

const warehouseAddresses = [
    {
        id: 1,
        title: "РЦ Артем",
        address: "Приморский край, г. Артем, ул. 2-я Рабочая, д. 162, корп. 2",
        lat: 43.346340,
        long: 132.104934,
    },
    // {
    //     id: 2,
    //     title: "РЦ Санкт-Петербург",
    //     address: "Ленинградская обл., Всеволожский р-н, Кузьмоловский гп, Заводская ул., зд. 361",
    //     lat: 60.102288,
    //     long: 30.479308,
    // },
    {
        id: 3,
        title: "РЦ Белый Раст",
        address: "Российская Федерация, Московская область, Дмитровский городской округ, деревня Зараменье, дом 43",
        lat: 56.143454,
        long: 37.417482,
    },
    {
        id: 4,
        title: "РЦ Новосибирск",
        address: "г. Новосибирск, ул. Петухова, 71к1",
        lat: 54.941901,
        long: 82.916459,
    },
    {
        id: 5,
        title: "РЦ Екатеринбург",
        address: "Свердловская область, г. Верхняя Пышма, поселок Залесье, проезд Индустриальный 1 корп. 1",
        lat: 56.968096,
        long: 60.662845,
    }
];

function Geography() {
    const [activeItemId, setActiveItemId] = useState(1);
    const [defaultMark, setDefaultMark] = useState([43.346340, 132.104934]);

    return (
        <section className="geography" id="geography">
            <div className="container">
                <h1>География</h1>
                <div className="geography__wrapper">
                    <div className="geography__content">
                        {
                            warehouseAddresses.map(el => {
                                return (
                                    <div
                                        className={classNames({
                                                "geography__item": true,
                                                "active": activeItemId === el.id,
                                        })}
                                        key={el.id}
                                        onClick={() => {
                                            setActiveItemId(el.id)
                                            console.log("el.lat", el.lat);
                                            console.log("el.long", el.long);
                                            setDefaultMark([el.lat, el.long])
                                        }}
                                    >
                                        <p className="geography__title">{el.title}</p>
                                        <p className="geography__desc">{el.address}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="geography__map-wrapper">
                        <YMaps>
                            <Map
                                className="geography__map"
                                defaultState={{
                                    center: defaultMark,
                                    zoom: 13,
                                }}
                                state={{
                                    center: defaultMark,
                                    zoom: 13,
                                }}
                                height="100%"
                                width="100%"
                            >
                                <Placemark
                                    key={1}
                                    geometry={defaultMark}
                                    options={{
                                        iconColor: "#FF9457",
                                        controls: [],
                                        iconLayout: "default#image",
                                        iconImageSize: [23, 33],
                                        iconImageHref: MapsPoint,
                                    }}
                                />
                            </Map>
                        </YMaps>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Geography;