import i18n from "i18next";
// import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const resources = {
    en: {
        translation: {
            "copyright": "Ⓒ BITSERVIS, 2024. All rights reserved",
            "details": "More details",

        }
    },
    ru: {
        translation: {
            "copyright": "Ⓒ БИТСЕРВИС, 2024. Все права защищены",
        }
    }
};


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "ru",

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });


export default i18n;