import React from "react";
import ContactPhoto from "../../../assets/images/contact_photo_2x.jpg";
import ArrowLeft from "../../../assets/icons/arrow_left.svg";
import ArrowDown from "../../../assets/icons/arrow__down.svg";
import "./index.scss";

function Contacts() {
  return (
    <section className="contacts" id="contacts">
      <div className="container">
        <div className="contacts__wrapper">
          <div className="contacts__content">
            <h1>Контактная информация</h1>
            {/*<p className="contacts__name">Кирилл Маценко</p>*/}
            {/*<p className="contacts__position">Руководитель отдела автоматизации и развития логистики</p>*/}
            {/*<a className="contacts__mail" href="mailto: Kirill.matsenko@simpel-nsk.ru">Kirill.matsenko@simpel-nsk.ru</a>*/}
            {/*<a className="contacts__phone" href="tel: 8(916)7143939">8(916)7143939</a>*/}
            <p className="contacts__name">Отдел персонала г. Москва</p>
            <a className="contacts__phone" href="tel: +7(915)4087876">
              +7 (915) 408-78-76
            </a>
            <p className="contacts__name">Отдел персонала г. Екатеринбург</p>
            <a className="contacts__phone" href="tel: +7(912)6120081">
              +7 (912) 612-00-81
            </a>
            <p className="contacts__name">3PL-услуги</p>
            <a className="contacts__phone" href="tel: +7(916)7143939">
              +7 (916) 714-39-39
            </a>
            <p className="contacts__name">Операционная логистика</p>
            <a className="contacts__phone" href="tel: +7(905)5446789">
              +7 (905) 544-67-89
            </a>
            <p className="contacts__name">Эксплуатация</p>
            <a className="contacts__phone" href="tel: +7(916)5623183">
              +7 (916) 562-31-83
            </a>
            <p className="contacts__name">Сотрудничество</p>
            <a className="contacts__phone" href="tel: 8(916)7143939">
              8 (916) 714-39-39
            </a>
          </div>
          <div className="contacts__photo">
            <img className="contacts__arrow" src={ArrowLeft} alt="" />
            <img
              className="contacts__arrow contacts__arrow--down"
              src={ArrowDown}
              alt=""
            />
            <img className="contacts__img" src={ContactPhoto} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
export default Contacts;
