import React from "react";
// import AnchorLink from "react-anchor-link-smooth-scroll";
import Line from "../../../assets/images/line.svg";
import "./index.scss";

function Main() {
    return (
        <section className="main" id="main">
            <div className="container">
                <div className="main__wrapper">
                    <h1>
                        Планирование,
                        стратегический анализ,
                        взгляд в будущее
                    </h1>
                    {/*<AnchorLink offset="50" href="#byshop" className="btn btn_primary">Складская логистика</AnchorLink>*/}
                    <img src={Line} alt=""/>
                </div>
            </div>
        </section>
    )
}

export default Main;