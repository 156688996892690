import React from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import About from "../components/About";
import Main from "../components/Main";
import Advantage from "../components/Advantage";
import Geography from "../components/Geography";
import Contacts from "../components/Contacts";
import Vacancies from "../components/Vacancies";

function MainPage() {
    return (
        <>
            <Header page="main" />
            <Main />
            <About />
            <Advantage />
            <Geography />
            <Vacancies />
            <Contacts />
            <Footer />
        </>
    )
}

export default MainPage